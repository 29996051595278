import { GlobalSchemaFields, populatedListCtor } from '@/api/mongooseTypes';
import queryString from 'query-string';

export class IWarehouseStore extends GlobalSchemaFields {
  /** @type {string} **/
  store;
  /** @type {string} **/
  user;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }

  // constructor(obj) {
  //   super(obj);
  //   obj.listedPrice = populatedCtor(obj.listedPrice, number);
  //   obj.discountedPrice = populatedCtor(obj.discountedPrice, number);
  //   obj.store = populatedCtor(obj.store, IStore);
  //   obj.product = populatedCtor(obj.booking, IProduct);
  //   Object.assign(this, obj);
  // }
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function assignStoreUsers(api, storeId, data) {
  return (await api.patch(`toca/store-users/${storeId}/assign-users-store`, data))?.data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getMatchedUsers(api, storeId, data, options) {
  return (await api.get(`toca/store-users/${storeId}/match-users?${queryString.stringify(data)}`))
    .data;
}

/**
 * @param {AxiosInstance} api
 * @param {{profileIds: string[]}} data
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getMatchedStores(api, data, options) {
  return (await api.get(`toca/store-users/match-stores?${queryString.stringify(data)}`)).data;
}
