import { IAttachment } from '@/api/attachment';
import { IClientProfile, IMasterProfile } from '@/api/auth/membership';
import { crudUpdate } from '@/api/crud';
import {
  GlobalSchemaFields,
  IDateRange,
  populatedArrayCtor,
  populatedCtor,
  populatedListCtor,
} from '@/api/mongooseTypes';
import { IServiceProduct } from '@/api/serviceProduct';
import { ITicketsPackage } from '@/api/ticketPackage';
import { ITimetable } from '@/api/timetable';
import { merge } from 'lodash';

export async function validStayTime(api, id) {
  return (await api.get(`toca/master/booking/validStayTime/${id}`)).data;
}

export async function settleBooking(api, id, payload) {
  return (await api.post(`/toca/admin/booking/settleBooking/${id}`, payload)).data;
}

export async function rescheduleBooking(api, id, startAt) {
  return (
    await api.patch(`/toca/admin/booking/reschedule/${id}`, {
      startAt,
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {number}
 */
export async function amountPayable(api, id) {
  return (await api.get(`toca/admin/booking/payment/${id}`)).data;
}
/**
 * @param {AxiosInstance} api
 * @param {string} id
 */
export async function voidBooking(api, id) {
  return (await api.post(`/toca/admin/booking/void/${id}`)).data;
}
/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {'0$created'|'1$arrived'|'97$cancelled'|'98$absent'|'99$completed'} status
 */
export async function markStatus(api, id, status) {
  await crudUpdate(api, 'toca/admin/bookings', id, { status: status });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {boolean} [late]
 */
export async function markLate(api, id, late = true) {
  await crudUpdate(api, 'toca/admin/bookings', id, { late: late });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {boolean} [calledCustomerForBookingConfirmation]
 */
export async function markCalledCustomerForBookingConfirmation(
  api,
  id,
  calledCustomerForBookingConfirmation = true,
) {
  await crudUpdate(api, 'toca/admin/bookings', id, {
    calledCustomerForBookingConfirmation: calledCustomerForBookingConfirmation,
  });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {boolean} paid
 */
export async function markPaid(api, id, paid) {
  await crudUpdate(api, 'toca/admin/bookings', id, { paid: paid });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {object} colorProportion
 */
export async function patchColorProportion(api, id, colorProportion) {
  await api.patch(`toca/admin/bookings/${id}/serviceRecord`, { colorProportion: colorProportion });
}
/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {object} patch
 */
export async function patchServiceRecord(api, id, patch) {
  await api.patch(`toca/admin/bookings/${id}/serviceRecord`, patch);
}
/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {object} patch
 */
export async function patchHairCondition(api, id, patch) {
  await api.patch(`toca/admin/bookings/${id}/serviceRecord`, patch);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {IBooking}
 */
export async function readBooking(api, id, options) {
  const data = (await api.get(`toca/admin/bookings/${id}`, { params: options })).data;
  return populatedCtor(data, IBooking);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {IBooking}
 */
export async function masterReadBooking(api, id, options) {
  const data = (await api.get(`toca/master/bookings/${id}`, { params: options })).data;
  return populatedCtor(data, IBooking);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {IBooking}
 */
export async function cancelBooking(api, id) {
  const data = (await api.patch(`toca/admin/cancel/booking/${id}`)).data;
  return populatedCtor(data, IBooking);
}

/**
 * @param {AxiosInstance} api
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: string}} [options]
 * @return {IBooking}
 */
export async function listBooking(api, options) {
  const data = (await api.get('toca/admin/bookings', { params: options })).data;
  return populatedListCtor(data, IBooking);
}

/**
 * @param {AxiosInstance} api
 * @param {{timeStart: string, timeEnd: string }}[options]
 * @return {Promise<IBooking[]>}
 */
export async function listBookingByDate(api, options = {}) {
  return populatedArrayCtor(
    (
      await api.get('toca/admin/booking/filterByTimetables', {
        params: merge(
          {
            filter: {
              'time.start': {
                $gte: options.timeStart,
                $lte: options.timeEnd,
              },
            },
          },
          options,
        ),
      })
    ).data,
    IBooking,
  );
}

/**
 * @param {AxiosInstance} api
 * @param {string} id booking id
 * @param {{creditGain: number, creditDeduct: number, creditGainTo?: string, paymentMethod?:string}} payload
 * @return {Promise<void>}
 */
export async function completeBooking(api, id, payload) {
  await api.post('toca/admin/booking/patchAndCompleteBookingThenPatchAssistant/' + id, payload);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {IBooking}
 */
export async function clientRecommendation(api, id, options) {
  return (await api.get(`toca/booking/recommendations/${id}`, { params: options })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {IBooking} bookingDetails
 * @return {Promise<{
 *   existingPackage?: ITicketsPackage;
 *   differentHairLength?: {
 *     serviceProduct: IServiceProduct;
 *     fee: number;
 *     multiplier: number;
 *   };
 *   singleTreatment?: IServiceProduct;
 *   otherDiscount?: {
 *     name: string;
 *     description: string;
 *     percentageDiscount: number;
 *   };
 *   needExtraFeeForMasterSpecified?: number;
 *   needHairCut?: number;
 *   creditDeduct?: number;
 *   creditGain?: number;
 *   amountPayable: number;
 * }>}
 */
export async function computePayment(api, bookingDetails) {
  const { data } = await api.post('toca/admin/booking/computePayment/', bookingDetails);
  return {
    ...data,
    existingPackage: populatedCtor(data.existingPackage, ITicketsPackage),
    differentHairLength: data.differentHairLength && {
      ...data.differentHairLength,
      serviceProduct: populatedCtor(data.differentHairLength.serviceProduct, IServiceProduct),
    },
    singleTreatment: populatedCtor(data.singleTreatment, IServiceProduct),
  };
}

/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */
export async function autocompleteLabels(api, search) {
  return (
    await api.get('toca/clientProfile/autocompleteLabels', {
      params: {
        search: search,
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {number | Date} date
 * @param {string} [mid]
 * @return {Promise<{histogram: [{time: string, count: number}],master?: IMasterProfile}>}
 */
export async function checkBooking(api, date, mid) {
  return (await api.get('toca/booking/checkBooking', { params: { date: date, mid } })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */

export async function autocompleteDiscountLabels(api, search) {
  return (
    await api.get('toca/clientProfile/autocompleteDiscountLabels', {
      params: {
        search: search,
      },
    })
  ).data;
}

//^^^^^^^^^^^^^^

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<string[]>}
 */
export async function patchPurchasedProducts(api, id, products) {
  // const data = await crudUpdate(api, 'toca/admin/bookings', id, { purchasedProducts: products });
  return (
    await api.patch(`toca/admin/booking/purchasePhysicalProducts/${id}`, {
      purchasedProducts: products,
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {Object} payload
 * @return {Promise<{booking: IBooking, timeTable: ITimetable, commit: Boolean}>}
 */
export async function createBookingAndTimetable(api, payload) {
  return (await api.post('toca/admin/booking/createBookingAndTimetable', payload)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {Object} payload
 * @return {Promise<{booking: IBooking, timeTable: ITimetable, commit: Boolean}>}
 */
export async function createPOSBookingOrder(api, id) {
  return await api.post(`toca/booking/${id}/order`);
}

export const BookingStatus = Object.freeze({
  created: '0$created',
  arrived: '1$arrived',
  cancelled: '97$cancelled',
  absent: '98$absent',
  completed: '99$completed',
});

export class IBooking extends GlobalSchemaFields {
  /** @type {string} **/
  sid;
  /** @type {IClientProfile} **/
  created_by;
  /** @type {'0$created'|'1$arrived'|'97$cancelled'|'98$absent'|'99$completed'} **/
  status;

  /** @type {IDateRange} */
  time;
  /** @type {IServiceDetails} **/
  serviceDetails;
  /** @type {number} **/
  amountPayable;
  /** @type {{name: string, description: string, percentageDiscount: number}} */
  otherDiscount;
  // /** @type {boolean} **/
  // paid;
  /**
   * remarks of order / from customer
   * @type {string}
   */
  remarks;
  /** @type {boolean} **/
  late;
  /** @type {boolean} **/
  calledCustomerForBookingConfirmation;
  /** @type {boolean} **/
  isVoided;
  /** @type {'admin'|'client'} **/
  cancelledBy;

  /** @type {IMasterProfile} **/
  firstAssignedMaster;
  /** @type {IMasterProfile} **/
  firstAssistantMaster;
  /** @type {IMasterProfile} **/
  assignedMaster;
  /** @type {IMasterProfile} **/
  assistantMaster;
  /** @type {IServiceRecord} */
  serviceRecord;
  /**@type {ITimetable} */
  _timetable;

  constructor(obj) {
    super(obj);
    obj.assignedMaster = populatedCtor(obj.assignedMaster, IMasterProfile);
    obj.assistantMaster = populatedCtor(obj.assistantMaster, IMasterProfile);
    obj.time = populatedCtor(obj.time, IDateRange);
    obj.serviceDetails = populatedCtor(obj.serviceDetails || {}, IServiceDetails);
    obj.otherDiscount = obj.otherDiscount || {};
    obj.serviceRecord = populatedCtor(obj.serviceRecord || {}, IServiceRecord);
    obj._timetable = populatedCtor(obj._timetable, ITimetable);
    Object.assign(this, obj);
  }
}

export class IServiceDetails {
  /** @type {IServiceProduct} **/
  serviceProduct;
  /** @type {ITicketsPackage} **/
  packageSpecified;
  /** @type {IMasterProfile} **/
  masterSpecified;
  /** @type {boolean} **/
  needHairCut;
  /** @type {boolean} **/
  needExtraFeeForMasterSpecified;
  /** @type {boolean} **/
  isRedo;

  constructor(obj) {
    obj.serviceProduct = populatedCtor(obj.serviceProduct, IServiceProduct);
    obj.packageSpecified = populatedCtor(obj.packageSpecified, ITicketsPackage);
    obj.masterSpecified = populatedCtor(obj.masterSpecified, IMasterProfile);
    Object.assign(this, obj);
  }
}

export class IServiceRecord {
  /** @type {IAttachment} **/
  beforeServicePhoto;
  /** @type {IAttachment} **/
  afterServicePhoto;
  /**
   * servicing remarks, added by master. different from booking.remarks
   * @type {string}
   */
  remarks;
  /** @type {IColorProportion} **/
  colorProportion;

  constructor(obj) {
    obj.beforeServicePhoto = populatedCtor(obj.beforeServicePhoto, IAttachment);
    obj.afterServicePhoto = populatedCtor(obj.afterServicePhoto, IAttachment);
    obj.colorProportion = populatedCtor(obj.colorProportion || {}, IColorProportion);
    Object.assign(this, obj);
  }
}

export class IColorProportion {
  firstRound;
  secondRound;
  constructor(obj) {
    const initRoundObject = v => ({
      color1: [],
      color2: [],
      color3: [],
      unit1: [],
      unit2: [],
      unit3: [],
      duration: v === 1 ? 30 : 20,
    });
    obj.firstRound = obj.firstRound || initRoundObject(1);
    obj.secondRound = obj.secondRound || initRoundObject(2);
    Object.assign(this, obj);
  }
}
