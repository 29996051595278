/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */

export async function autocompleteTags(api, search) {
  return (
    await api.get('toca/admin/PhysicalProduct/autocompleteTags', {
      params: {
        search: search,
      },
    })
  ).data;
}

export async function updatePhysicalProductByCSV(api, payload) {
  const csvBlob = new Blob([payload], { type: 'text/csv' });
  const fd = new FormData();
  fd.append('file', csvBlob);

  return (
    await api.post('toca/admin/PhysicalProduct/updateByCSV', fd, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;
}

export async function importNewPhysicalProductByCSV(api, payload) {
  const csvBlob = new Blob([payload], { type: 'text/csv' });
  const fd = new FormData();
  fd.append('file', csvBlob);

  return (
    await api.post('toca/admin/PhysicalProduct/importNewProduct', fd, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @returns {Promise<string[]>}
 */
export async function getBarcodeList(api) {
  return (await api.get('toca/admin/PhysicalProduct/barcode-list')).data;
}

export async function getPhysicalProductByCategory(api, params) {
  return (
    await api.get('toca/admin/PhysicalProduct/getProductListByCategory', {
      params: {
        category: params.category,
      },
    })
  ).data;
}
