var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListView',{attrs:{"title":"Stock Changing","search":_vm.search,"data":_vm.data,"header":_vm.header,"enableCreate":false,"page":_vm.page,"loading":_vm.isLoading},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":function () { return false; },"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex align-center selectListCol"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"min-width":"max-content"},attrs:{"label":"From","prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.selectedStart),callback:function ($$v) {_vm.selectedStart=$$v},expression:"selectedStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeDialog),callback:function ($$v) {_vm.startTimeDialog=$$v},expression:"startTimeDialog"}},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"input":function($event){_vm.startTimeDialog = false}},model:{value:(_vm.selectedStart),callback:function ($$v) {_vm.selectedStart=$$v},expression:"selectedStart"}})],1)],1),_c('div',{staticClass:"d-flex align-center selectListCol"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"max-width":"max-content"},attrs:{"label":"To","prepend-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(_vm.selectedEnd),callback:function ($$v) {_vm.selectedEnd=$$v},expression:"selectedEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeDialog),callback:function ($$v) {_vm.endTimeDialog=$$v},expression:"endTimeDialog"}},[_c('v-date-picker',{attrs:{"header-color":"primary"},on:{"input":function($event){_vm.endTimeDialog = false}},model:{value:(_vm.selectedEnd),callback:function ($$v) {_vm.selectedEnd=$$v},expression:"selectedEnd"}})],1)],1),(_vm.warehouse == null)?_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.warehouseList,"item-text":"name","item-value":"_id","label":"Warehouse","outlined":"","clearable":""},model:{value:(_vm.filterWarehouse),callback:function ($$v) {_vm.filterWarehouse=$$v},expression:"filterWarehouse"}}):_vm._e(),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.productActivityLogUpdateReasonOptions,"label":"Reason","outlined":"","clearable":""},model:{value:(_vm.filterReason),callback:function ($$v) {_vm.filterReason=$$v},expression:"filterReason"}})],1)]},proxy:true},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt))+" ")]}},{key:"item._FromWarehouse",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item._FromWarehouse || 'N/A'))])]}},{key:"item._ToWarehouse",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item._ToWarehouse || 'N/A'))])]}},{key:"item._stock",fn:function(ref){
var item = ref.item;
return [(item.updateReason === _vm.ProductActivityLogUpdateReason.Adjustment)?_c('div',[_c('span',{staticClass:"blue--text font-weight-bold"},[_vm._v(" "+_vm._s(item.afterStock))])]):_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.beforeStock)+" ")]),_c('v-icon',{attrs:{"color":item.afterStock === item.beforeStock
              ? 'gray'
              : item.afterStock > item.beforeStock
              ? 'success'
              : 'error'}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.afterStock))])],1)]}},{key:"item.updateReason",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("translateOptions")(item.updateReason,_vm.productActivityLogUpdateReasonOptions, 'text'))+" "),(_vm.get(item, 'remarks', false))?[_vm._v("("+_vm._s(item.remarks)+")")]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }