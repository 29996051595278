<template>
  <div>
    <custom-label :title="title" :item-field-name="itemFieldName" :text="text" />
    <slot :attrs="$attrs" :props="$props" :on="$listeners">
      <v-text-field v-bind="{ ...$attrs, ...$props }" v-on="$listeners" v-model="valueProxy" />
    </slot>
  </div>
</template>

<script>
import customLabel from './customLabel';

export default {
  name: 'labelWithTextField',
  components: {
    customLabel,
  },
  props: {
    title: String,
    itemFieldName: String,
    text: {},
    dense: { Boolean, default: false },
    outlined: { Boolean, default: true },
    required: { Boolean, default: true },
    value: {},
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
