import { populatedCtor } from '@/api/mongooseTypes';

/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getUserPreference(api) {
  return (await api.get(`toca/admin/UserPreference/value`)).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getPaymentPreference(api) {
  return (await api.get(`toca/admin/PaymentPreference/value`)).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getPrintOrderReceiptPreference(api) {
  return (await api.get(`toca/admin/PrintOrderReceiptPreference/value`)).data;
}

// Get by master, timetable check invoice
/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getPaymentPreferenceByMaster(api) {
  return (await api.get(`toca/master/PaymentPreference/value`)).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<AdminPreference>}
 */
export async function getAdminPreference(api) {
  return populatedCtor((await api.get(`toca/admin/AdminPreference/value`)).data, AdminPreference);
}

/**
 * @param {AxiosInstance} api
 * @param payload
 * @return {Promise}
 */
export async function updateUserPreference(api, payload) {
  await api.patch(`toca/admin/UserPreference/value`, payload);
}

/**
 * @param {AxiosInstance} api
 * @param payload
 * @return {Promise}
 */
export async function updateAdminPreference(api, payload) {
  await api.patch(`toca/admin/AdminPreference/value`, payload);
}

/**
 * @param {AxiosInstance} api
 * @param payload
 * @return {Promise}
 */
export async function updatePaymentPreference(api, payload) {
  await api.patch(`toca/admin/PaymentPreference/value`, payload);
}

/**
 * @param {AxiosInstance} api
 * @param payload
 * @return {Promise}
 */
export async function updatePrintOrderReceiptPreference(api, payload) {
  await api.patch(`toca/admin/PrintOrderReceiptPreference/value`, payload);
}

/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getRestaurantTable(api) {
  return (await api.get(`toca/admin/RestaurantTable/value`)).data;
}

// Get by master
/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getRestaurantTableByMaster(api) {
  return (await api.get(`toca/master/RestaurantTable/value`)).data;
}

/**
 * @param {AxiosInstance} api
 * @param payload
 * @return {Promise}
 */
export async function updateRestaurantTable(api, payload) {
  await api.patch(`toca/admin/RestaurantTable/value`, payload);
}

export class AdminPreference {
  /** @type {Array<{img: string;label: string}>} **/
  hairConditionReferencePhoto;
  /** @type {Array<string>} **/
  scalpCondition;
  /** @type {Array<string>} **/
  workDone;
  /** @type {Array<string>} **/
  volume;
  /** @type {Array<string>} **/
  condition;
  /** @type {Array<string>} **/
  expectedColor;
  /** @type {Array<{description: string;label: string; value: string}>} **/
  colorOptions;

  constructor(obj) {
    obj.hairConditionReferencePhoto = obj.hairConditionReferencePhoto || [];
    obj.scalpCondition = obj.scalpCondition || [];
    obj.workDone = obj.workDone || [];
    obj.volume = obj.volume || [];
    obj.condition = obj.condition || [];
    obj.expectedColor = obj.expectedColor || [];
    obj.colorOptions = obj.colorOptions || [];
    Object.assign(this, obj);
  }
}

/**
 * @param {AxiosInstance} api
 * @return {Promise}
 */
export async function getOpeningHours(api) {
  return (await api.get(`toca/openingHours`)).data;
}
