<template>
  <v-dialog v-model="valueProxy">
    <v-card min-height="75VH">
      <v-card-title primary-title class="justify-center">
        <v-spacer />
        <v-btn icon @click="doClose()">
          <v-icon dense> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <!--      <v-tabs v-model="activeTab">-->
      <!--        <v-tab v-for="(tab, index) in tabs" :key="index">-->
      <!--          {{ tab.title }}-->
      <!--        </v-tab>-->
      <!--        &lt;!&ndash; <v-tab-item v-for="(tab, index) in tabs" :key="index">-->
      <!--          {{ tab.value }}-->
      <!--        </v-tab-item> &ndash;&gt;-->
      <!--      </v-tabs>-->
      <ListView
        :title="'Products Pricing'"
        @reload="reload"
        :data="data"
        :header="header"
        :page.sync="page"
        :search.sync="search"
        :loading="isLoading"
        enableSelect
        enableActionBtn
        v-model="selectedData"
        :enableCreate="false"
      >
        <template #filter>
          <v-select
            v-model="queryFilter.category"
            :items="productCategories"
            item-text="title"
            item-value="_id"
            label="Category"
            clearable
            solo
            height="56"
            style="width: 224px"
          />
        </template>
        <template v-slot:item.name="{ item }">
          {{ parseLocalizedString(item.name).en }}
        </template>
        <!-- <template v-slot:filter v-if="showIssueReport">
              <v-tabs v-model="tab">
                <v-tab @click="switchTab(`/lkt/v1/admin/LKTAssetLog/container/${container?._id}`)">{{
                  $t('tab.asset_logs')
                }}</v-tab>
                // TODO: handle container issue report if need to display
                <v-tab @click="switchTab('lkt/v1/staff/LKTAssetIssueReports')">{{
                  $t('tab.issue_reports')
                }}</v-tab>
              </v-tabs>
            </template>
            <template v-slot:item.title="{ item }">
              {{ logsTitleTranslation(item?.title) }}
            </template>
            <template v-slot:item.activity="{ item }">
              {{ ActivityTypeMap[item?.title] }}
            </template>
            <template v-slot:item.recordDate="{ item }">
              {{ item?.recordDate ?? item?.created_at | datePrettify }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip :color="StatusColorMap[item?.status]" class="statusChip">
                <span style="color: var(--v-white_light-base)">{{ item?.status | status }}</span>
              </v-chip>
            </template>
            <template v-slot:item.followUp="{ item }">
              <v-custom-btn @click.stop="showFollowUpForm(item)">Follow Up</v-custom-btn>
            </template> -->
      </ListView>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="saveAndClose" :disabled="selectAllIsLoadingRef">save</v-btn>
        <v-spacer />
        <v-btn @click="assignProducts" :loading="selectAllIsLoadingRef">
          Select {{ totalNumProduct }} Products</v-btn
        >
        <v-spacer />
        <v-btn @click="deselectAllProducts" :loading="selectAllIsLoadingRef">Unselect all</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <!-- <custom-dialog ref="confirm"></custom-dialog> -->
  </v-dialog>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import ListView from '@/components/ListView/custom';

// import CustomDialog from '@/components/CustomDialog';
import api from '@/api';
import { mapGetters } from 'vuex';
import { getMatchedProducts } from '@/api/productStore';
import { parseLocalizedString } from '@/services/utils';
import { getMatchedProducts as getWarehouseMatchedProducts } from '@/api/warehouseStock';
import { listProductCategories } from '@/api/productCategory';
import { getPhysicalProductByCategory } from '@/api/physicalProduct';

export default {
  name: 'ProductListDialog',
  mixins: [listViewPageMixin],
  components: {
    ListView,
    // CustomDialog,
  },
  props: {
    id: '',
    store: { type: Boolean, default: false },
    container: { type: Object, default: () => {} },
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      header: [{ text: 'Name', value: 'name' }],
      selectedData: [],
      activeTab: 0,
      removedIds: [],
      tabs: [
        { title: 'Restaurant Product', value: 'restaurantProduct' },
        { title: 'Extension Product', value: 'extensionProduct' },
      ],
      populate: ['category'],
      queryFilter: {},
      productCategories: [],
      totalNumProduct: 0,
      selectAllIsLoadingRef: false,
    };
  },
  // data() {
  //   return {
  //     selectedURL: `toca/admin/warehouses`,
  //     populate: [
  //       { path: 'created_by', model: 'profile', select: ['name'] },
  //       { path: 'PIC', model: 'profile', select: ['name'] },
  //     ],
  //     tab: 0,
  //   };
  // },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return `toca/admin/PhysicalProducts`;
    },
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
    filter() {
      if (this.queryFilter.category === null) this.queryFilter.category = undefined;
      return { ...this.queryFilter };
    },
    // headers() {
    //   return [
    //     [
    //       { text: this.$t('headers.title'), value: 'title', width: '150px' },
    //       { text: this.$t('headers.date'), value: 'recordDate', width: '150px' },
    //       { text: this.$t('headers.createdBy'), value: 'created_by.name', width: '150px' },
    //     ],
    //     // [
    //     //   { text: this.$t('headers.title'), value: 'title', width: '150px' },
    //     //   { text: this.$t('headers.createdBy'), value: 'created_by.name', width: '150px' },
    //     //   { text: this.$t('headers.createdAt'), value: 'recordDate', width: '150px' },
    //     //   { text: this.$t('headers.urgencyLvl'), value: 'urgency', width: '150px' },
    //     //   { text: this.$t('headers.status'), value: 'status', width: '150px' },
    //     //   { text: this.$t('headers.followUp'), value: 'followUp', width: '150px' },
    //     // ],
    //   ];
    // },
  },
  watch: {
    // restfulURL: {
    //   immediate: true,
    //   handler() {
    //     this.reload();
    //   },
    // },
    data: {
      // immediate: true,
      handler(data) {
        // NOTE: WHEN NEW DATA

        const prepData = data.docs?.map(doc => doc);
        this.totalNumProduct = data.total;

        this.getMatchedProducts(prepData?.map(d => d._id.toString()));
      },
    },
    selectedData: {
      handler(selectedData) {
        const removedArray = this.data.docs
          .map(d => d._id.toString())
          .filter(element => !selectedData.map(d => d._id.toString()).includes(element));
        const prepIds = [...new Set([...this.removedIds, ...removedArray])];
        this.removedIds = prepIds;
      },
    },
  },
  mounted() {
    this.getProductCategories();
  },
  methods: {
    // logsTitleTranslation(title) {
    //   const translated = i18n.t(`containerLogTitle.${title}`);
    //   return !translated.includes('containerLogTitle.') ? translated : title;
    // },

    showCreate() {
      this.editItem = null;
      if (this.isAssetLog) this.showAssetLogEditDialog = true;
      else this.showIssueReportEditDialog = true;
    },
    navDetails(item) {
      this.editItem = item;
      if (this.isAssetLog) this.showAssetLogEditDialog = true;
      else this.showIssueReportEditDialog = true;
    },
    // showFollowUpForm(item) {
    //   this.showFollowUpEditDialog = true;
    //   this.editItem = item;
    // },
    // switchTab(url) {
    //   this.selectedURL = url;
    //   this.page = 1;
    // },
    doClose() {
      this.valueProxy = false;
    },
    // async createLog(newModel) {
    //   const isConfirm = await this.$refs.confirm.open(
    //     'Confirm Create',
    //     `Create New ${newModel?.title ?? ''}?`,
    //   );
    //   if (!isConfirm) return;
    //   try {
    //     await createAssetLog(api, newModel);
    //     this.successPrompt('Created Successfully');
    //     this.reload();
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // async updateLog(newModel) {
    //   const isConfirm = await this.$refs.confirm.open(
    //     'Confirm Update',
    //     `Update ${newModel?.title}?`,
    //   );
    //   if (!isConfirm) return;
    //   try {
    //     await updateAssetLog(api, newModel?._id, newModel);
    //     this.successPrompt('Updated Successfully');
    //     this.reload();
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async successPrompt(msg) {
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: msg ? msg : 'Success',
        type: 'success',
        color: 'success',
      });
    },
    saveAndClose() {
      console.log('saveProducts -> ', this.selectedData.length);
      this.$emit('saveProducts', {
        products: this.selectedData,
        removeProductIds: this.removedIds,
      });
    },
    async getMatchedProducts(productListIds) {
      // TODO: CONTROL FROM OUTER COMPONENT
      let fetchedData = [];
      console.log('fetching matched products');
      if (this.store) {
        fetchedData = await getMatchedProducts(api, this.id, {
          productIds: productListIds,
        });
      }
      if (!this.store) {
        console.log('this should be triggered');
        fetchedData = await getWarehouseMatchedProducts(api, this.id, {
          productIds: productListIds,
        });
      }

      this.selectedData = [...new Set([...this.selectedData, ...fetchedData])];
    },
    parseLocalizedString,
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    async assignProducts() {
      try {
        this.selectAllIsLoadingRef = true;
        const params = {
          category: this.queryFilter.category,
        };
        const res = await getPhysicalProductByCategory(api, params);
        this.selectedData = [...new Set([...this.selectedData, ...res])];
        this.removedIds = [];
      } catch (error) {
        console.log(error);
      } finally {
        this.selectAllIsLoadingRef = false;
      }
    },
    async deselectAllProducts() {
      try {
        this.selectAllIsLoadingRef = true;

        const params = {
          category: this.queryFilter.category,
        };
        const res = await getPhysicalProductByCategory(api, params);

        this.removedIds = res.map(d => d._id.toString());
        this.selectedData = [];
      } catch (error) {
        console.log(error);
      } finally {
        this.selectAllIsLoadingRef = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
