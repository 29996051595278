<template>
  <div>
    <ListView
      title="Stock Changing"
      @reload="reload"
      @create="actionCreate"
      @navDetails="() => false"
      :search.sync="search"
      :data="data"
      :header="header"
      :enableCreate="false"
      :page.sync="page"
      :loading="isLoading"
    >
      <template #searchBox>
        <div class="d-flex" style="gap: 10px">
          <div class="d-flex align-center selectListCol">
            <v-menu
              v-model="startTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedStart"
                  label="From"
                  prepend-icon="mdi-calendar"
                  style="min-width: max-content"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="selectedStart"
                @input="startTimeDialog = false"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex align-center selectListCol">
            <!--            <input type="date" class="input" v-model="selectedEnd" @input="passEndTime()" />-->
            <v-menu
              v-model="endTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedEnd"
                  label="To"
                  prepend-icon="mdi-calendar"
                  style="max-width: max-content"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                header-color="primary"
                v-model="selectedEnd"
                @input="endTimeDialog = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <v-select
            v-if="warehouse == null"
            v-model="filterWarehouse"
            :items="warehouseList"
            item-text="name"
            item-value="_id"
            label="Warehouse"
            style="max-width: 300px"
            outlined
            clearable
          />
          <v-select
            v-model="filterReason"
            :items="productActivityLogUpdateReasonOptions"
            label="Reason"
            style="max-width: 300px"
            outlined
            clearable
          />
        </div>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | moment }}
      </template>
      <template v-slot:item._FromWarehouse="{ item }">
        <div>{{ item._FromWarehouse || 'N/A' }}</div>
      </template>
      <template v-slot:item._ToWarehouse="{ item }">
        <div>{{ item._ToWarehouse || 'N/A' }}</div>
      </template>
      <template v-slot:item._stock="{ item }">
        <div v-if="item.updateReason === ProductActivityLogUpdateReason.Adjustment">
          <span class="blue--text font-weight-bold"> {{ item.afterStock }}</span>
        </div>
        <div v-else>
          <span class="font-weight-bold">
            {{ item.beforeStock }}
          </span>

          <v-icon
            :color="
              item.afterStock === item.beforeStock
                ? 'gray'
                : item.afterStock > item.beforeStock
                ? 'success'
                : 'error'
            "
            >mdi-arrow-right</v-icon
          >
          <span class="font-weight-bold"> {{ item.afterStock }}</span>
        </div>
      </template>
      <template v-slot:item.updateReason="{ item }">
        {{ item.updateReason | translateOptions(productActivityLogUpdateReasonOptions, 'text') }}
        <template v-if="get(item, 'remarks', false)">({{ item.remarks }})</template>
      </template>
    </ListView>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { crudRead, crudList } from '@/api/crud';
import { assignStoreUsers } from '@/api/storeUser';
import { RESTFUL } from '@/data/constants';
import ListView from '@/components/ListView';
import UserListDialog from '@/components/User/UserListDialog';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid } from '@/services/utils';
import { ProductActivityLogUpdateReason } from '@/data/constants';
import { productActivityLogUpdateReasonOptions } from '@/data/optionsConstants';
import moment from 'moment';
import { get } from 'lodash';

export default {
  name: 'StoreUserList',
  components: { CustomLabel, ListView, UserListDialog },
  mixins: [listViewPageMixin],
  props: {
    id: '',
    warehouse: '',
  },
  data() {
    return {
      ProductActivityLogUpdateReason,
      productActivityLogUpdateReasonOptions,
      details: null,
      header: [
        { text: 'Updated At', value: 'updatedAt' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Updated Warehouse', value: 'warehouse.name' },
        { text: 'From Warehouse', value: '_FromWarehouse' },
        { text: 'Stock', value: '_stock', width: '140px' },
        { text: 'To Warehouse', value: '_ToWarehouse' },
        { text: 'Reason', value: 'updateReason' },
        // { text: 'Product Name(HK)', value: 'product.name' },
      ],
      data: [],
      search: '',
      populate: [{ path: 'warehouse', select: ['name'] }, { path: 'updatedBy' }],
      warehouseList: [],
      filterWarehouse: null,
      filterReason: null,
      startTimeDialog: false,
      endTimeDialog: false,
      searchTime: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    this.reloadWarehouse();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    restfulURL() {
      return RESTFUL.productActivityLog.read;
    },
    filter() {
      const out = {
        product: getOid(this.id),
      };
      if (this.warehouse) out['warehouse'] = getOid(this.warehouse);
      if (this.filterWarehouse) {
        out['warehouse'] = getOid(this.filterWarehouse);
      }
      if (this.filterReason) {
        out['updateReason'] = this.filterReason;
      }
      if (this.searchTime.start && this.searchTime.end) {
        out['created_at'] = {
          ...(this.searchTime.start ? { $gte: moment(this.searchTime.start).startOf('day') } : {}),
          ...(this.searchTime.end ? { $lte: moment(this.searchTime.end).endOf('day') } : {}),
        };
      }
      return out;
    },
    selectedStart: {
      get() {
        if (this.searchTime.start != null)
          return moment(this.searchTime.start).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.searchTime.start = +moment(v).startOf('day');
      },
    },
    selectedEnd: {
      get() {
        if (this.searchTime.end != null) return moment(this.searchTime.end).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.searchTime.end = +moment(v).endOf('day');
      },
    },
  },
  methods: {
    get,
    async reloadWarehouse() {
      this.warehouseList =
        (
          await crudList(api, RESTFUL.warehouses.list, {
            limit: 1000,
          })
        )?.docs || [];
    },
  },
};
</script>
