<template>
  <div>
    <v-container>
      <div><h2>Supplier Details</h2></div>
      <v-divider class="mb-5" />
      <div v-if="details">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-row class="mr-3 ml-3">
            <v-col class="ml-auto" md="auto">
              <!-- <v-btn class="mr-2" @click="print">Print</v-btn> -->

              <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save</v-btn>
            </v-col>
          </v-row>
          <v-form>
            <v-row class="mr-3 ml-3">
              <!-- <v-col cols="12" md="6">
              <custom-label item-field-name="Table QR Code" />
              <QrcodeVue id="qrCode" renderAs="svg" :value="id" :size="180" level="H" />
            </v-col> -->
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <custom-label item-field-name="Name" />
                    <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.name"
                        label="Name"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <custom-label item-field-name="Contact" />
                    <ValidationProvider v-slot="{ errors }" name="Contact" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.contact"
                        label="Contact"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <custom-label item-field-name="Location" />
                    <ValidationProvider v-slot="{ errors }" name="Location" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.location"
                        label="Location"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </div>
      <div v-else class="d-flex justify-center" style="padding-top: 20%">
        <h2>No Record to found</h2>
      </div>
    </v-container>
  </div>
</template>

<script>
import api from '@/api';
import { getOid } from '@/services/utils';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { assignWarehouseStock, updateWarehouseStock } from '@/api/warehouseStock';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import ListView from '@/components/ListView';
import listViewPageMixin from '@/services/listViewPageMixin';
import ProductListDialog from '@/components/PhysicalProduct/ProductListDialog.vue';
import WarehouseStockDetailDialog from '@/components/WarehouseStock/WarehouseStockDetailDialog.vue';

export default {
  name: 'SupplierDetails',
  components: { CustomLabel },
  mixins: [],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      data: [],
    };
  },
  mounted() {
    this.loadSupplier();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
  },
  methods: {
    async loadSupplier() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.suppliers.read, this.id);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    // async reload() {
    //   if (!this.isNew) {
    //     this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
    //   }
    // },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.suppliers.create, this.details)
          : await crudUpdate(api, RESTFUL.suppliers.update, this.id, this.details);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'supplier',
            params: {
              id: model._id,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
