<template>
  <v-dialog v-model="valueProxy" width="90%" max-width="100vw">
    <v-card>
      <v-card-title>
        <slot name="Images" style="font-size: 20px">Image(s)</slot>
        <v-spacer />
        <v-btn icon @click="valueProxy = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-carousel>
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="attnThumbHref(image, 'hd')"
            contain
          />
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import editDialogMixin from '@/services/editDialogMixin';

export default {
  name: 'CarouselDialog',
  components: {},
  mixins: [editDialogMixin],
  props: {
    images: [],
  },
  methods: {
    attnThumbHref,
  },
};
</script>
