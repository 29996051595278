var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListView',{attrs:{"title":"Products","page":_vm.page,"search":_vm.search,"data":_vm.data,"header":_vm.header},on:{"reload":_vm.reload,"create":_vm.actionCreateProductStock,"update:page":function($event){_vm.page=$event},"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name ? _vm.parseLocalizedString(item.product.name).en : '')+" ")]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [(item.activityLog)?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.activityLog.beforeStock)+" ")]),_c('v-icon',{attrs:{"color":item.activityLog.afterStock === item.activityLog.beforeStock
              ? 'gray'
              : item.activityLog.afterStock > item.activityLog.beforeStock
              ? 'success'
              : 'error'}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.activityLog.afterStock))]),(item.activityLog.afterStock > item.activityLog.beforeStock)?_c('span',{staticClass:"ml-3",staticStyle:{"color":"#8ddc88"}},[_vm._v("(+"+_vm._s(item.activityLog.afterStock - item.activityLog.beforeStock)+")")]):_vm._e(),(item.activityLog.afterStock < item.activityLog.beforeStock)?_c('span',{staticClass:"ml-3",staticStyle:{"color":"#ff5252"}},[_vm._v("(-"+_vm._s(item.activityLog.beforeStock - item.activityLog.afterStock)+")")]):_vm._e()],1):_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item._stock))])]}},{key:"item.reason",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.get(item, 'activityLog.updateReason', '-'))+" "),(_vm.get(item, 'activityLog.remarks', false))?[_vm._v(" ("+_vm._s(item.activityLog.remarks)+") ")]:_vm._e()],2)]}},{key:"item.edit",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.actionDetailsProductStock(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.detail",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.actionDetailsActivityLog(item)}}},[_vm._v("mdi-menu")])]}}])}),(_vm.displayProductList)?_c('ProductListDialog',{attrs:{"id":_vm.id},on:{"saveProducts":_vm.assignWarehouseStock},model:{value:(_vm.displayProductList),callback:function ($$v) {_vm.displayProductList=$$v},expression:"displayProductList"}}):_vm._e(),(_vm.displayWarehouseStock)?_c('WarehouseStockDetailDialog',{attrs:{"warehouseStockId":_vm.warehouseStockId},on:{"updateWarehouseStock":_vm.saveWarehouseStock},model:{value:(_vm.displayWarehouseStock),callback:function ($$v) {_vm.displayWarehouseStock=$$v},expression:"displayWarehouseStock"}}):_vm._e(),_c('ProductActivityLogDialog',{key:_vm.productId,attrs:{"show":_vm.displayProductActivityLogDialog,"warehouse":_vm.warehouse,"productId":_vm.productId,"productName":_vm.productName},on:{"close":function($event){_vm.displayProductActivityLogDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }