import { GlobalSchemaFields, populatedListCtor } from '@/api/mongooseTypes';

export class IStore extends GlobalSchemaFields {
  /** @type {string} **/
  sid;
  /** @type {string} **/
  name;
  /** @type {string} **/
  description;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }

  // constructor(obj) {
  //   super(obj);
  //   obj.time = populatedCtor(obj.time, IDateRange);
  //   obj.store = populatedCtor(obj.store, IStore);
  //   obj.booking = populatedCtor(obj.booking, IBooking);
  //   obj._client = populatedCtor(obj._client, IProfile);
  //   obj.master = populatedCtor(obj.master, IProfile);
  //   Object.assign(this, obj);
  // }
}

/**
 * @param {AxiosInstance} api
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function getStores(api, options) {
  return populatedListCtor((await api.get(`toca/admin/stores`, { params: options })).data, IStore);
}

/**
 * @param {AxiosInstance} api
 * @param {string} warehouseId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getStoresByWarehouseId(api, warehouseId, options) {
  return populatedListCtor(
    (await api.get(`toca/admin/warehouse-stores/${warehouseId}`, { params: options })).data,
    IStore,
  );
}
