import { IAttachment } from '@/api/attachment';
import {
  GlobalSchemaFields,
  parseDate,
  populatedArrayCtor,
  populatedCtor,
  populatedListCtor,
  populatedReadCtor,
} from '@/api/mongooseTypes';
import { RESTFUL } from '@/data/constants';
import { AxiosInstance } from 'axios';
import { merge } from 'lodash';

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {string} uuid
 * @return {Promise<void>}
 */
export async function updateClientHandband(api, id, uuid) {
  return (await api.patch('toca/clientProfile/update-uuid/' + id, { uuid })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} userId
 * @param {*} patch
 */
export async function patchUser(api, userId, patch) {
  return (await api.patch(RESTFUL.user.create + '/' + userId, patch)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @param {*} patch
 */
export async function patchProfile(api, profileId, patch) {
  return (await api.patch(RESTFUL.profile.create + '/' + profileId, patch)).data;
}

//For master patch Profile
/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @param {*} patch
 */
export async function patchProfileByMaster(api, profileId, patch) {
  return (await api.patch(RESTFUL.profile.masterCreate + '/' + profileId, patch)).data;
}
//For Cashier patch Profile
/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @param {*} patch
 */
export async function patchProfileByCashier(api, profileId, patch) {
  return (await api.patch(RESTFUL.profile.cashierCreate + '/' + profileId, patch)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {Partial<IProfile & IUser>} profileAndUserFields
 */
export async function createUser(api, profileAndUserFields) {
  return (await api.post(RESTFUL.user.create, profileAndUserFields)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 */
export async function getUserListByProfileId(api, profileId) {
  return (
    await api.get(RESTFUL.user.list, { params: { filter: { profiles: profileId }, select: '_id' } })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param params
 */
export async function listProfiles(api, params) {
  const { data } = await api.get(RESTFUL.profile.list, { params });
  return populatedListCtor(data, IProfile);
}

/**
 * @param {AxiosInstance} api
 * @param {string|string[]} ids
 * @param [params]
 */
export async function readProfile(api, ids, params) {
  if (Array.isArray(ids)) {
    const { data } = await api.get(RESTFUL.profile.read + '/,' + ids.join(','), {
      params,
    });
    return populatedReadCtor(data, IProfile);
  } else {
    const { data } = await api.get(RESTFUL.profile.read + '/' + ids, { params });
    return populatedCtor(data, IProfile);
  }
}

export async function readProfileByMaster(api, ids, params) {
  if (Array.isArray(ids)) {
    const { data } = await api.get(RESTFUL.profile.masterRead + '/,' + ids.join(','), {
      params,
    });
    return populatedReadCtor(data, IProfile);
  } else {
    const { data } = await api.get(RESTFUL.profile.masterRead + '/' + ids, { params });
    return populatedCtor(data, IProfile);
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {string[]} labels
 * @return {Promise<void>}
 */
export async function updateClientLabels(api, id, labels) {
  await api.patch('toca/clientProfile/updateLabels/' + id, { labels: labels });
}

//@@discountLabels vvvvvvvv
export async function updateClientDiscountLabels(api, id, discountLabels) {
  await api.patch('toca/clientProfile/updateDiscountLabels/' + id, {
    discountLabels: discountLabels,
  });
}

// /**
//  * @param {AxiosInstance} api
//  * @param {string} id
//  * @param {string[]} discountLabels
//  * @return {Promise<void>}
//  */
// export async function updateDiscountLabels(api, id, discountLabels) {
//   await api.patch('toca/clientProfile/updateDiscountLabels/' + id, {
//     discountLabels: discountLabels,
//   });
// }

//^^^^^^^^^^^
/**
 * @param {AxiosInstance} api
 * @param id - User ID
 * @param {string}newPw
 */
export async function resetMasterPassword(api, id, newPw) {
  return await api.post(`users/${id}/resetPassword`, { newPw: newPw });
}
/**
 * @param {AxiosInstance} api
 * @param id - User ID
 * @param {string}newPw
 */
export async function resetPassword(api, id, newPw) {
  return await api.post(`users/${id}/resetPassword`, { newPw: newPw });
}

/**
 * @param {AxiosInstance} api
 * @param [params]
 * @return {Promise<PaginationResult<IMasterProfile>>}
 */
export async function listMasterProfiles(api, params) {
  const { data } = await api.get('toca/admin/MasterProfiles', {
    params: merge(params, {
      filter: {
        status: ProfileStatus.accepted,
      },
    }),
  });
  return populatedListCtor(data, IProfile);
}
/**
 * @param {AxiosInstance} api
 * @param [params]
 * @return {Promise<PaginationResult<IMasterProfile>>}
 */
export async function listAllMasterProfilesIncludingInactive(api, params) {
  const { data } = await api.get('toca/admin/MasterProfiles', {
    params: merge(params),
  });
  return populatedListCtor(data, IProfile);
}

//For master get the data for show on table
/**
 * @param {AxiosInstance} api
 * @param [params]
 * @return {Promise<PaginationResult<IMasterProfile>>}
 */
export async function listAllMasterProfilesIncludingInactiveByMaster(api, params) {
  const { data } = await api.get('toca/master/MasterProfiles', {
    params: merge(params),
  });
  return populatedListCtor(data, IProfile);
}

//For master get the data for show on table
/**
 * @param {AxiosInstance} api
 * @param [params]
 * @return {Promise<PaginationResult<IMasterProfile>>}
 */
export async function listAllMasterProfilesIncludingInactiveByCashier(api, params) {
  const { data } = await api.get('toca/cashier/MasterProfiles', {
    params: merge(params),
  });
  return populatedListCtor(data, IProfile);
}

export class IUser extends GlobalSchemaFields {
  /** @type {String} */
  username;
  /** @type {String} */
  pw;
  /** @type {String} */
  email;
  /**
   * @type {boolean}
   * @readonly
   */
  hasPw;
}

export const ProfileStatus = Object.freeze({
  requested: '0$requested',
  accepted: '1$accepted',
  rejected: '99$rejected',
});

export class IProfile extends GlobalSchemaFields {
  /** @type {string} */
  role;
  /** @type {string} */
  name;
  /** @type {string} */
  phone;
  /** @type {string} */
  phone2;
  /** @type {string} */
  status;

  constructor(obj) {
    super(obj);
    const clazz =
      {
        'toca.master': IMasterProfile,
        'toca.client': IClientProfile,
      }[obj.role] || IProfile;
    if (!(this instanceof clazz)) {
      return new clazz(obj);
    }
    Object.assign(this, obj);
  }
}

export class IMasterProfile extends IProfile {
  /** @type {number} */
  servicingCapacity;
  /** @type {(IAttachment | string)[]} */
  previousHardCopy;
  /** @type {number} */
  bnAutoAssignPriority;
  /** @type {number} */
  bnDisplayOrder;

  /**
   * he has resigned if 'inactive'
   * @type {boolean}
   */
  get isActive() {
    return this.enabled && this.status === ProfileStatus.accepted;
  }

  /** @type {number} */
  get _servicingCapacity() {
    return (
      // handle "inactive" master, so the timetable column shows red
      !this.isActive
        ? 0
        : Number.isFinite(this.servicingCapacity)
        ? this.servicingCapacity
        : Number.POSITIVE_INFINITY
    );
  }

  constructor(obj) {
    super(obj);
    obj.previousHardCopy = populatedArrayCtor(obj.previousHardCopy || [], IAttachment);
    obj.bnEntryDate = parseDate(obj.bnEntryDate);
    Object.assign(this, obj);
  }
}

export class IClientProfile extends IProfile {
  /** @type {{heart?: boolean, labels: String[], discountLabels: String[]}} */
  metadata;
  /** @type {Boolean} */
  isBlocked;
  /** @type {String} */
  birthday;
  /** @type {String} */
  gender;
  /** @type {Boolean} */
  oldCustomer;
  /** @type {String} */
  howToKnowBN;
  /** @type {String} */
  otherWayToKnowBN;
  /** @type {Boolean} */
  watchedBN3minIntroduction;
  /** @type {(IAttachment | string)[]} */
  previousHardCopy;
  /** @type {String} */
  phone2;

  constructor(obj) {
    super(obj);
    obj.gender = obj.gender || null;
    obj.metadata = obj.metadata || {};
    obj.metadata.labels = obj.metadata.labels || [];
    obj.metadata.discountLabels = obj.metadata.discountLabels || [];
    obj.metadata.heart = obj.metadata.heart || false;
    obj.previousHardCopy = populatedArrayCtor(obj.previousHardCopy || [], IAttachment);
    Object.assign(this, obj);
  }
}
