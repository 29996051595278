<template>
  <div style="max-width: 150px">
    <v-img
      :max-height="imageSize"
      :max-width="imageSize"
      :min-height="imageSize"
      :min-width="imageSize"
      :key="item.img"
      :src="attnThumbHref(item.img, ThumbnailSize)"
    >
      <template v-slot:placeholder>
        <v-img
          :max-height="imageSize"
          :max-width="imageSize"
          :min-height="imageSize"
          :min-width="imageSize"
          contain
          src="@/assets/TocaLogo.svg"
        />
      </template>
    </v-img>
    <span style="color: initial"> {{ item.label }} </span>
  </div>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
export default {
  name: 'imgWithPlaceholder',
  props: {
    item: {},
    ThumbnailSize: {
      default: 256,
    },
    imageSize: {
      default: 150,
    },
  },
  methods: {
    attnThumbHref,
  },
};
</script>

<style scoped></style>
