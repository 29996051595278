/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */
import { merge } from 'lodash';

export async function listProductCategories(api, options) {
  return (await api.get('toca/admin/productCategories', { params: merge({ limit: 100 }, options) }))
    .data.docs;
}

export async function listPhysicalProducts(api, options) {
  return (await api.get('toca/admin/PhysicalProducts/', { params: options })).data;
}
