<template>
  <div>
    <ListView
      title="Users"
      @reload="reload"
      @create="actionCreate"
      @navDetails="() => false"
      :search.sync="search"
      :data="data"
      :header="header"
    >
      <!-- <template v-slot:item.name_en="{ item }">
          {{ parseLocalizedString(item.name).en }}
        </template>
        <template v-slot:item.name_hk="{ item }">
          {{ parseLocalizedString(item.name)['zh-hk'] }}
        </template> -->
    </ListView>
    <UserListDialog v-if="displayUserList" v-model="displayUserList" :id="id" @saveUsers="save" />
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';

import { crudRead } from '@/api/crud';
import { assignStoreUsers } from '@/api/storeUser';
import { RESTFUL } from '@/data/constants';
import ListView from '@/components/ListView';
import UserListDialog from '@/components/User/UserListDialog';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid } from '@/services/utils';

export default {
  name: 'StoreUserList',
  components: { CustomLabel, ListView, UserListDialog },
  mixins: [listViewPageMixin],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      header: [
        { text: 'User Name', value: 'user.username' },
        { text: 'Role', value: 'user._roles' },
        // { text: 'Product Name(HK)', value: 'product.name' },
      ],
      data: [],
      search: '',
      displayUserList: false,
    };
  },
  mounted() {
    this.loadStore();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    restfulURL() {
      return `toca/store-users/${this.id}/users`;
    },
  },
  methods: {
    async loadStore() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.stores.read, this.id);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    // async reload() {
    //   if (!this.isNew) {
    //     this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
    //   }
    // },
    async actionCreate() {
      // TODO: POP UP WORKSPACES LIST HERE
      this.displayUserList = true;
    },
    async actionCancel() {
      this.displayUserList = false;
    },
    async save(productsData) {
      const userIds = productsData.users.map(p => getOid(p));
      const removeUserIds = productsData.removeUserIds;
      try {
        // if (!(await this.$refs.form?.validate?.())) {
        //   return;
        // }
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await assignStoreUsers(api, this.id, {
          userIds,
          removeUserIds,
        });

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });

        // if (this.isNew) {
        //   this.$router.replace({
        //     name: 'vipProduct',
        //     params: {
        //       id: model._id,
        //     },
        //   });
        // }
        this.displayUserList = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
