<template>
  <div class="d-flex align-baseline">
    <div>{{ get(profile, 'name', '-') }}</div>
    <v-btn v-show="profile" class="ml-1" small @click.stop="goToProfile" icon>
      <v-icon small color="primary"> mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { getOid } from '@/services/utils';
import { get } from 'lodash';
export default {
  name: 'ProfileNameTag',
  props: {
    profile: null,
  },
  methods: {
    get,
    goToProfile() {
      //window.open(window.location.origin + '/admin/profiles/' + getOid(this.profile));
      let id = getOid(this.profile);
      if (this.profile.hasOwnProperty('clientId')) id = this.profile.clientId; // <!-- **** Notice: special handling for data coming from the POS system ****-->

      const router = this.$router.resolve({
        name: 'Profile',
        params: { id },
      });
      window.open(router.href, `profile-${id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
