import crudMixin from '@/services/crudMixin';

export default {
  mixins: [crudMixin],
  props: {
    value: {},
    id: {},
    /**
     * if no data provided, will fetch from crudURL with the id specified.
     * if data specified, all api operations will be disabled and instead only emit events.
     */
    data: {},
  },
  data() {
    return {
      model: {},
      loading: false,
    };
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isCreate() {
      return this.id == null && !this.data;
    },
  },
  watch: {
    value: {
      async handler(show) {
        if (show) {
          await this.reload();
          await this.$nextTick();
          await this.$nextTick();
          this.$refs.form?.reset?.();
        }
      },
    },
    id() {
      if (this.valueProxy) {
        this.reload();
      }
    },
    data() {
      if (this.valueProxy) {
        this.reload();
      }
    },
  },
  methods: {
    reload() {
      // placeholder to prevent null reference
    },
    doClose() {
      this.valueProxy = false;
    },
    async promptUpdate(patch = this.model, id = this.id) {
      if (!patch && !(await this.$refs.form?.validate?.())) {
        return;
      }
      return crudMixin.methods.promptUpdate.call(this, patch, id);
    },
    async doUpdate(patch) {
      let x;
      if (this.data) {
        if (patch) {
          Object.assign(this.model, patch);
        }
        x = this.model;
      } else {
        x = await crudMixin.methods.doUpdate.cal(this, patch);
      }
      this.$emit('update', this.model);
      return x;
    },
    async promptDelete(id = this.id) {
      if (this.data) {
        return this.doDelete(id);
      } else {
        return crudMixin.methods.promptDelete.call(this, id);
      }
    },
    async doDelete(id) {
      let x;
      if (this.data) {
        x = true;
      } else {
        x = await crudMixin.methods.doDelete.call(this, id);
      }
      this.$emit('delete', this.model);
      return x;
    },
  },
};
