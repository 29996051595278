import { IMasterProfile, IProfile } from '@/api/auth/membership';
import { IBooking } from '@/api/bookings';
import { crudRead, crudUpdate } from '@/api/crud';
import {
  GlobalSchemaFields,
  IDateRange,
  populatedArrayCtor,
  populatedCtor,
} from '@/api/mongooseTypes';
import { IStore } from '@/api/store';
import { getOid } from '@/services/utils';

/**
 * @param {AxiosInstance} api
 * @param {ITimetable} payload
 * @return {Promise<ITimetable>}
 */
export async function createTimeTable(api, payload) {
  const data = (await api.post('toca/admin/Timetables', payload)).data;
  return populatedCtor(data, ITimetable);
}

/**
 * @param {AxiosInstance} api
 * @param {String} id
 * @param {ITimetable} payload
 * @return {Promise<ITimetable>}
 */
export async function updateTimeTable(api, id, payload) {
  const data = await crudUpdate(api, 'toca/admin/timetables', id, payload);
  return populatedCtor(data, ITimetable);
}

/**
 * @param {AxiosInstance} api
 * @param {String} id
 * @param {*} [options]
 * @return {Promise<ITimetable>}
 */
export async function readTimeTables(api, id, options) {
  const data = await crudRead(api, 'toca/admin/timetables', id, options);
  return populatedCtor(data, ITimetable);
}
/**
 * @param {AxiosInstance} api
 * @param {String} id
 * @return {Promise<ITimetable>}
 */
export async function readTimeTable(api, id) {
  const data = await crudRead(api, 'toca/admin/timetable/getOne', id);
  return populatedCtor(data, ITimetable);
}
/**
 * @param {AxiosInstance} api
 * @param {String} id
 * @return {Promise<ITimetable>}
 */
export async function readTimeTableByMaster(api, id) {
  const data = await crudRead(api, 'toca/master/timetable/getOne', id);
  return populatedCtor(data, ITimetable);
}

/**
 * @param {AxiosInstance} api
 * @param {{timeStart: *, timeEnd: * }}[options]
 * @return {Promise<ITimetable[]>}
 */
export async function listTimeTableByDate(api, options) {
  const data = (await api.get('toca/admin/timetable/getByDate', { params: options })).data;
  return populatedArrayCtor(data, ITimetable);
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<IMasterProfile[]>}
 */
// export async function getTodayOnDuty(api) {
//   const data = (await api.get('toca/admin/timetable/todayOnDuty')).data;
//   return populatedArrayCtor(data, IMasterProfile);
// }

/**
 * @param {AxiosInstance} api
 * @param {string} masterId
 * @return {Promise<void>}
 */
// export async function addTodayOnDuty(api, masterId) {
//   await api.post('toca/admin/timetable/todayOnDuty/' + masterId);
// }

/**
 * @param {AxiosInstance} api
 * @param {string} masterId
 * @return {Promise<void>}
 */
// export async function deleteTodayOnDuty(api, masterId) {
//   await api.delete('toca/admin/timetable/todayOnDuty/' + masterId);
// }

export class ITimetable extends GlobalSchemaFields {
  /** @type {IStore} **/
  store;
  /** @type {IBooking} **/
  booking;
  /** @type {IClientProfile | string} **/
  _client;
  /** @type {'0$created'|'1$arrived'|'97$cancelled'|'98$absent'|'99$completed'} **/
  _status;
  /** @type {IMasterProfile | string} **/
  master;
  /** @type {IMasterProfile | string} **/
  assistantMaster;
  /**
   * for UI layout use only
   * @type {boolean}
   */
  _isAssistant;
  /** @type {string} **/
  seat;
  /** @type {IDateRange} */
  time;
  /** @type {Boolean} */
  isRest;
  /** @type {number} */
  columnIndex = 0;
  ///@@isVoided vvvvvvv
  /**  @type {boolean}  */
  isVoided;
  //@@@^^^^^^^^^^^^
  //region getters for Timetable event interface
  /**
   * column id in timetable UI
   * @return {string}
   */
  get masterColumnId() {
    return getOid(this._isAssistant ? this.assistantMaster : this.master);
  }

  /**
   * *this annotation is just for code navigation
   * @interface $bookmark$timetableColumnId
   */
  /**
   * this key is matched against {@link src/views/Timetable/index.vue#allColumns}
   * @return {{_id: string}}
   */
  get column() {
    return { _id: this.masterColumnId + ':' + this.columnIndex };
  }
  get start() {
    return this.time.start;
  }
  get end() {
    return this.time.end;
  }
  //endregion

  constructor(obj) {
    super(obj);
    obj.time = populatedCtor(obj.time, IDateRange);
    obj.store = populatedCtor(obj.store, IStore);
    obj.booking = populatedCtor(obj.booking, IBooking);
    obj._client = populatedCtor(obj._client, IProfile);
    obj.master = populatedCtor(obj.master, IProfile);
    Object.assign(this, obj);
  }
}
