<template>
  <v-row>
    <v-col>
      <v-menu
        v-model="fromDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            outlined
            readonly
            :label="label"
            :value="fromDateDisp"
            v-on="on"
            prepend-inner-icon="mdi-calendar"
            @click:prepend-inner="fromDateMenu = true"
          />
        </template>
        <v-date-picker
          :disabled="this.disableDatePicker"
          :min="minDate"
          :max="maxDate"
          v-model="fromDateVal"
          no-title
          @input="fromDateMenu = false"
          :events="events"
          :eventColor="eventColor"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'DatePickerWrapperInputMenu',
  props: {
    value: {},
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    events: [],
    eventColor: {
      type: String,
      default: 'primary',
    },
    label: {},
    disableDatePicker: { default: false },
  },
  data() {
    return {
      fromDateMenu: false,
    };
  },
  computed: {
    fromDateVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    fromDateDisp() {
      return this.fromDateVal ? this.fromDateVal : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.filled-color {
  background-color: red;
}

.v-text-field {
  margin-bottom: -24px;
}
</style>
