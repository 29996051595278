<template>
  <div>
    <v-container>
      <div><h2>Store Details</h2></div>
      <v-divider class="mb-5" />
      <div v-if="details">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-row class="mr-3 ml-3">
            <v-col class="ml-auto" md="auto">
              <!-- <v-btn class="mr-2" @click="print">Print</v-btn> -->

              <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save</v-btn>
            </v-col>
          </v-row>
          <v-form>
            <v-row class="mr-3 ml-3">
              <!-- <v-col cols="12" md="6">
              <custom-label item-field-name="Table QR Code" />
              <QrcodeVue id="qrCode" renderAs="svg" :value="id" :size="180" level="H" />
            </v-col> -->
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <custom-label item-field-name="Name" />
                    <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.name"
                        label="Name"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <custom-label item-field-name="Contact" />
                    <ValidationProvider v-slot="{ errors }" name="Contact" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.contact"
                        label="Contact"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <custom-label item-field-name="Location" />
                    <ValidationProvider v-slot="{ errors }" name="Location" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.location"
                        label="Location"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <!-- <v-col cols="12">
                    **************** Notices ****************
                    **************** Notices ****************
                    **************** Notices ****************
                    Notice: This field affects the POS login, please do not modify it
                    **************** Notices ****************
                    **************** Notices ****************
                    **************** Notices ****************
                    <custom-label item-field-name="SID" />
                    <ValidationProvider v-slot="{ errors }" name="SID" rules="required">
                      <v-text-field
                        outlined
                        v-model="details.sid"
                        label="SID"
                        :error="!!errors.length"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col> -->
                  <v-col v-if="!isNew" cols="12">
                    <custom-label item-field-name="Warehouse" />
                    <ValidationProvider v-slot="{ errors }" name="warehouse" rules="required">
                      <autocomplete
                        label="Warehouse"
                        :path="'toca/admin/warehouses'"
                        :params="{
                          sort: { created_at: -1 },
                        }"
                        item-text="name"
                        item-value="_id"
                        v-model="details.warehouse"
                        :error-messages="errors"
                        :clearable="true"
                        :has-items-slot="true"
                        disabled
                      >
                        <template v-slot:items="content">
                          <span>{{ get(content, 'item.name', '') }}</span>
                        </template>
                      </autocomplete>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </div>
      <div v-else class="d-flex justify-center" style="padding-top: 20%">
        <h2>No Record to found</h2>
      </div>
    </v-container>
    <!-- <ListView
      title="Warehouses"
      @reload="reload"
      @create="actionCreate"
      :search.sync="search"
      :data="data"
      :header="header"
    />
    <WarehouseListDialog
      v-if="displayWarehouseList"
      v-model="displayWarehouseList"
      @assignWarehouses="assignWarehouses"
      :id="id"
    /> -->
    <StoreProductList v-if="!isNew" :id="id" />
    <StoreUserList v-if="!isNew" :id="id" />

    <!-- <WarehouseListDialog
      v-model="displayWarehouseList"
      :id="editId"
      :title="title"
      @create="reload"
      @update="reload"
      @delete="reload"
    /> -->
    <!-- <v-dialog v-model="displayWarehouseList" max-width="800px">
      <v-card>
        <v-card-title> Warehouses</v-card-title>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn @click="actionCancel"> Cancel</v-btn>
          <v-btn @click="saveWarehouseToStore"> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import WarehouseListDialog from '@/components/Warehouse/WarehouseListDialog';
import StoreProductList from '@/components/Store/StoreProductList';
import StoreUserList from '@/components/Store/StoreUserList';
import { assignStoreWarehouses } from '@/api/warehouseStore';
import { getOid } from '@/services/utils';
import { get } from 'lodash';

import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import { ValidationProvider } from 'vee-validate';
import Autocomplete from '@/components/Autocomplete/Autocomplete';

export default {
  name: 'StoreDetails',
  components: {
    CustomLabel,
    Autocomplete,
    StoreProductList,
    StoreUserList,
    ValidationProvider,
  },
  mixins: [],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      header: [
        { text: 'Warehouse Name', value: 'warehouse.name' },
        { text: 'Store Name', value: 'store.name' },
      ],
      data: [],
      search: '',
      displayWarehouseList: false,
    };
  },
  mounted() {
    this.loadStore();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    getId() {
      return this.id;
    },
    // restfulURL() {
    //   return `toca/warehouse-stores/${this.id}/warehouses`;
    // },
  },
  methods: {
    get,
    async loadStore() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.stores.read, this.id);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    // async reload() {
    //   if (!this.isNew) {
    //     this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
    //   }
    // },
    async actionCreate() {
      // TODO: POP UP WORKSPACES LIST HERE
      this.displayWarehouseList = true;
    },
    async saveWarehouseToStore() {
      // TODO: create rest action here
    },
    async actionCancel() {
      this.displayWarehouseList = false;
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.stores.create, this.details)
          : await crudUpdate(api, RESTFUL.stores.update, this.id, this.details);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'store',
            params: {
              id: model._id,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async assignWarehouses(warehouseData) {
      const warehouseIds = warehouseData.warehouses.map(p => getOid(p));
      const removeWarehouseIds = warehouseData.removedIds || [];
      try {
        // if (!(await this.$refs.form?.validate?.())) {
        //   return;
        // }
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await assignStoreWarehouses(api, this.id, {
          warehouseIds,
          removeWarehouseIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        // if (this.isNew) {
        //   this.$router.replace({
        //     name: 'vipProduct',
        //     params: {
        //       id: model._id,
        //     },
        //   });
        // }
        this.displayWarehouseList = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
