import ListView from '@/components/ListView';
import { debounce, merge } from 'lodash';
import api from '@/api';

export default {
  components: { ListView },
  props: {
    appendFilter: {},
  },
  data() {
    return {
      search: '',
      page: 1,
      limit: 10,
      data: {
        docs: [],
        offset: 0,
        page: 1,
        pages: 1,
        limit: 10,
      },
      debouncedSearch: debounce(this.reload, 400),
      editDialog: false,
      editId: null,
      isLoading: false,
      populate: null,
      sort: { created_at: -1 },
    };
  },
  computed: {
    title: () => '', //placeholder
    restfulURL: () => '', //placeholder
    mergeParams() {
      return null;
    },
    filter() {
      return null;
    },
    computedFilter() {
      const f = merge({}, this.filter, this.appendFilter);
      if (Object.keys(f).length) {
        return f;
      }
      return null;
    },
    computedQuery() {
      return merge(
        {},
        this.computedFilter,
        {
          search: this.search,
          sort: this.sort,
        },
        {
          limit: this.limit,
        },
      );
    },
    //{search: this.search, limit: this.limit, ...this.computedFilter}
    computedQueryWatcher() {
      return JSON.stringify(this.computedQuery);
    },
    finalQuery() {
      return merge(
        {},
        this.computedFilter,
        {
          search: this.search,
          sort: this.sort,
        },
        {
          limit: this.limit,
          page: this.page,
        },
      );
    },
    finalQueryWatcher() {
      return JSON.stringify(this.finalQuery);
    },
  },
  watch: {
    computedQueryWatcher() {
      this.page = 1;
    },
    finalQueryWatcher() {
      this.debouncedSearch();
    },
  },
  beforeMount() {
    this.reload();
  },
  methods: {
    async reload() {
      this.editDialog = false;
      try {
        this.isLoading = true;
        await this.load();
      } catch (e) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: e,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async load() {
      this.data = (
        await api.get(this.restfulURL, {
          params: merge(
            {
              page: this.page,
              search: this.search,
              limit: this.limit,
              filter: this.computedFilter,
              populate: this.populate,
              sort: this.sort,
            },
            this.mergeParams,
          ),
        })
      ).data;
      this.data.docs = this.data.docs.map(x => x);
    },
    actionDetails(x) {
      this.$router.push({ path: String(x._id), append: true });
    },
    actionEdit(id) {
      this.$router.push({
        path: String(id),
        query: { edit: '1' },
        append: true,
      });
    },
    actionDelete() {},
    actionCreate() {
      this.editDialog = true;
    },
  },
};
