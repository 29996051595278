<template>
  <v-dialog v-model="valueProxy" width="60%" max-width="100vw">
    <v-card elevation="0" min-height="50VH">
      <v-card-title>
        <h2>
          {{ productName }}
        </h2>
        <v-spacer />
        <v-btn icon @click="valueProxy = false">
          <v-icon> mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text> <ProductActivityLogList :id="productId" :warehouse="warehouse" /></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductActivityLogList from '@/components/ProductActivityLog/ProductActivityLogList';

export default {
  name: 'ProductActivityLogDialog',
  components: { ProductActivityLogList },
  props: {
    productName: {},
    warehouse: '',
    productId: '',
    show: { default: false },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    valueProxy: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.overrideVCardText {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
