<template>
  <v-container>
    <v-row>
      <v-col v-if="enableBack" cols="12">
        <v-custom-btn @click="$emit('back')"
          ><v-icon dense>mdi-chevron-left</v-icon>{{ $t('common.back') }}</v-custom-btn
        >
      </v-col>
      <v-col cols="12">
        <div class="flex-between">
          <div>
            <slot name="title">
              <span v-if="title" class="mainTitle">
                {{ title }}
              </span>
            </slot>
          </div>
          <div v-if="enableActionBtn" class="flex-between">
            <slot name="action">
              <v-icon class="iconBtn" dense small @click="$emit('reload')">mdi-reload</v-icon>
              <v-btn v-if="enableCreate" @click="$emit('create')" class="ml-2">
                <v-icon dense>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </slot>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="enableSearch">
      <v-col style="flex: 2">
        <slot name="searchBox" :ctx="this">
          <v-text-field
            rounded
            solo
            filled
            clearable
            prepend-inner-icon="mdi-magnify"
            v-model="searchVal"
          />
        </slot>
      </v-col>
      <!--      <v-col style="position: relative">-->
      <!--        <slot name="filter"></slot>-->
      <!--      </v-col>-->
    </v-row>
    <slot name="filter"></slot>
    <v-data-table
      :items="data.docs"
      :server-items-length="data.pages || 1"
      :items-per-page="data.limit || 10"
      :disable-sort="true"
      hide-default-footer
      :headers="header"
      :calculate-widths="isFluid"
      :mobile-breakpoint="mobileBreakPoint"
      :item-class="itemClass"
      :show-select="enableSelect"
      :loading="loading"
      :item-key="itemKey"
      v-model="valueProxy"
      @click:row="x => $emit('navDetails', x)"
    >
      <template v-for="h in header" v-slot:[`item.${h.value}`]="{ item }">
        {{ get(item, h.value, '-') }}
      </template>
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="props">
        <slot :name="name" v-bind="props" />
      </template>
      <!-- <template #no-data>
        {{ $t('common.no_data') }}
      </template> -->
    </v-data-table>
    <v-row>
      <v-col :cols="cols">
        <slot name="card"></slot>
      </v-col>
    </v-row>
    <v-pagination v-if="enablePages" class="mt-4" v-model="pageVal" :length="data.pages || 1" />
    <slot></slot>
  </v-container>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'ListView',
  props: {
    title: '',
    search: {},
    page: {},
    data: { default: () => ({}) },
    enableCreate: { type: Boolean, default: true },
    enableBack: { type: Boolean, default: false },
    enableSearch: { type: Boolean, default: true },
    enablePages: { type: Boolean, default: true },
    enableSelect: { type: Boolean, default: false },
    enableActionBtn: { type: Boolean, default: true },
    populate: {},
    cols: { type: Number, default: 12 },
    header: {
      default: () => [{ text: 'ID', value: '_id' }],
    },
    isFluid: { type: Boolean, default: false },
    mobileBreakPoint: { default: 600 },
    value: { type: Array, default: () => [] },
    itemClass: { type: Function, default: () => {} },
    itemKey: { type: String, default: '_id' },
    loading: { type: Boolean, default: false },
  },
  emits: ['create', 'reload'],
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
    searchVal: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      },
    },
  },
  methods: {
    get,
  },
};
</script>

<style scoped></style>
