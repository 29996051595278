<template>
  <v-autocomplete
    class="autoCompleted"
    outlined
    v-bind="$attrs"
    :items="list"
    v-on="$listeners"
    :search-input.sync="search"
    :value="value"
    @input="x => $emit('input', x)"
    :disabled="disabled"
    :menu-props="{ offsetY: true }"
    no-filter
    append-icon="mdi-magnify"
  >
    <template v-if="hasItemsSlot" v-slot:item="x">
      <slot :item="x.item" name="items"></slot>
    </template>

    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>

    <template v-slot:append-item v-if="isLoadingDisplay">
      <center>
        <v-progress-circular
          color="primary"
          indeterminate
          v-intersect="handleIntersect"
        ></v-progress-circular>
      </center>
    </template>
  </v-autocomplete>
</template>

<script>
import { crudList } from '@/api/crud';
import api from '@/api';
import { debounce } from 'lodash';

export default {
  name: 'masterAutocomplete',
  props: {
    path: {},
    value: {},
    params: {},
    disabled: { type: Boolean, default: false },
    hasItemsSlot: { type: Boolean, default: false }, //TODO: replace with $slots
  },
  data() {
    return {
      docs: null,
      list: [],
      search: null,
      debounceToGetData: debounce(this.reload, 500),
    };
  },
  computed: {
    isLoadingDisplay() {
      return (this.docs?.page || 1) < (this.docs?.pages || 1) ? true : false;
    },
  },
  watch: {
    search: {
      immediate: true,
      handler(v) {
        this.debounceToGetData();
      },
    },
  },
  methods: {
    async reload() {
      if (!this.value)
        this.docs = await crudList(api, this.path, { search: this.search, ...this.params });

      if (this.docs?.page === 1) {
        this.list = this.docs?.docs || [];
      } else {
        this.list = this.list.concat(this.docs?.docs || []);
      }

      const itemValuePropertyName = this.$attrs['item-value'];
      if (
        this.value &&
        itemValuePropertyName &&
        !this.list.find(
          item =>
            item[itemValuePropertyName] === this.value ||
            item[itemValuePropertyName] === this.value?.[itemValuePropertyName],
        )
      ) {
        this.list = this.list.concat(
          (
            await crudList(api, this.path, {
              filter: { [`${itemValuePropertyName}`]: this.value },
            })
          ).docs,
        );
      }
    },
    handleIntersect(entries, observer) {
      if (!entries?.[0]?.isIntersecting) return;
      if (this.docs?.page < this.docs?.pages)
        this.params.page = (this.params.page || this.docs.page || 1) + 1;
      this.reload();
    },
  },
};
</script>

<style lang="scss">
.autoCompleted .v-icon {
  transform: rotate(0deg) !important;
}
</style>
