<template>
  <v-dialog v-model="valueProxy" width="60%" max-width="100vw">
    <ValidationObserver ref="form" v-slot="{ valid }">
      <v-card elevation="0" min-height="250">
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Listed Price" />
                <ValidationProvider v-slot="{ errors }" name="listed price" rules="required">
                  <v-text-field
                    v-model="model.listedPrice"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Discounted Price" />
                <ValidationProvider v-slot="{ errors }" name="Discounted Price" rules="required">
                  <v-text-field
                    v-model="model.discountedPrice"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" @click="resetProductPrice()"> Reset All Changes</v-btn>
          <v-spacer />
          <!---<v-btn color="primary" :disabled="!valid" @click="promptCreate()" v-if="isCreate">-->
          <v-btn color="primary" @click="promptUpdate()"> Update</v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import editDialogMixin from '@/services/editDialogMixin';
import CustomLabel from '../customFormLabel/customLabel';
import { cloneDeep, get } from 'lodash';
import { creditRecordAction, creditRecordStatus } from '@/data/optionsConstants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { getNameFromProfile } from '@/services/filters';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { RESTFUL } from '@/data/constants';
import { crudRead } from '@/api/crud';

export default {
  name: 'ProductStoreDetailDialog',
  components: {
    CustomLabel,
    // Autocomplete,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [editDialogMixin],
  emits: ['create'],
  props: {
    title: {},
    productStoreId: '',
  },
  data() {
    return {
      creditRecordAction,
      creditRecordStatus,
      model: {
        action: 'topup',
        status: '1$active',
        created_by: this.clientId ? [this.clientId] : [],
      },
    };
  },
  watch: {
    productStoreId: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['profile']),
  },
  methods: {
    getNameFromProfile,
    get,
    moment,
    async reload() {
      this.model = await crudRead(api, RESTFUL.productStores.read, this.productStoreId);
    },
    async promptUpdate() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }

      this.$emit('updateProductStore', this.model);
    },
    resetProductPrice() {
      this.$emit('resetProductPrice', this.model);
    },
  },
};
</script>

<style scoped lang="scss">
.overrideVCardText {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
