import { GlobalSchemaFields, populatedReadCtor } from '@/api/mongooseTypes';

/**
 * @param {AxiosInstance} api
 * @param {File} file
 */
export async function uploadSingleAttachment(api, file) {
  const fd = new FormData();
  fd.append('file', file);
  return (
    await api.post('attachment/upload', fd, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {Files} files
 */
export async function uploadMultipleAttachment(api, files) {
  //TODO: require optimization?
  const attachmentIds = [];
  for (const file of files) {
    const fd = new FormData();
    fd.append('file', file);
    const id = (
      await api.post('attachment/upload', fd, {
        headers: {
          Accept: 'application/json',
          'Content-Type': false,
        },
      })
    ).data._id;
    attachmentIds.push(id);
  }
  return attachmentIds;
}

export async function deleteAttachment(api, id) {
  return api.delete('attachments/' + id);
}

export async function uploadAttachment(api, formData) {
  return (await api.post('attachment/upload', formData)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string|string[]} ids
 * @param {{returnArray?: boolean}} options
 * @return {Promise<IAttachment[] | Record<string, IAttachment>>}
 */
export async function readAttachment(api, ids, options) {
  return populatedReadCtor(
    (
      await api.get('attachments/' + (Array.isArray(ids) ? ',' + ids.join(',') : ids), {
        params: options,
      })
    ).data,
    IAttachment,
  );
}

export class IAttachment extends GlobalSchemaFields {
  filename;
  length;
  href;
  links = [];
  contentType;
  thumbnail;

  constructor(obj) {
    super(obj);
    obj.links = obj.links || [];
    Object.assign(this, obj);
  }
}
