import { GlobalSchemaFields, populatedListCtor } from '@/api/mongooseTypes';
import queryString from 'query-string';

export class IProductStore extends GlobalSchemaFields {
  /** @type {string} **/
  store;
  /** @type {string} **/
  product;
  /** @type {number} **/
  listedPrice;
  /** @type {number} **/
  discountedPrice;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }

  // constructor(obj) {
  //   super(obj);
  //   obj.listedPrice = populatedCtor(obj.listedPrice, number);
  //   obj.discountedPrice = populatedCtor(obj.discountedPrice, number);
  //   obj.store = populatedCtor(obj.store, IStore);
  //   obj.product = populatedCtor(obj.booking, IProduct);
  //   Object.assign(this, obj);
  // }
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function assignStoreProducts(api, storeId, data) {
  console.log('data here passing', data);
  return (await api.patch(`toca/product-stores/${storeId}/assign-store-product`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} productStoreId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function updateStoreProduct(api, productStoreId, data) {
  return (await api.patch(`toca/product-stores/${productStoreId}`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getProductsByStoreId(api, storeId, options) {
  return (await api.get(`toca/product-stores/${storeId}/products`)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getMatchedProducts(api, storeId, data, options) {
  return (
    await api.get(`toca/product-stores/${storeId}/match-products?${queryString.stringify(data)}`)
  ).data;
}

export async function resetStoreProductPrice(api, productStoreId, data) {
  return (await api.post(`toca/product-stores/resetPrice/${productStoreId}`, data)).data;
}
