import { IProfile } from '@/api/auth/membership';
import {
  GlobalSchemaFields,
  parseDate,
  populatedCtor,
  populatedListCtor,
} from '@/api/mongooseTypes';
import { IServiceProduct } from '@/api/serviceProduct';

/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<ITicketsPackage>>}
 */
export async function getClientTickets(api, profileId, options) {
  return populatedListCtor(
    (await api.get(`toca/admin/TicketsPackage/getClientTickets/${profileId}`, { params: options }))
      .data,
    ITicketsPackage,
  );
}

export async function autocompletePaymentMethod(api, search) {
  return (
    await api.get('toca/admin/ticketsPackage/autocompletePaymentMethod', {
      params: {
        search: search,
      },
    })
  ).data;
}
export async function patchTicketShareWith(api, tid, pid) {
  return await api.patch(`toca/admin/ticketsPackage/shareClientTicketsPackage/${tid}/${pid}`);
}
export async function patchTicketUnShare(api, tid) {
  return await api.patch(`toca/admin/ticketsPackage/unshareClientTicketsPackage/${tid}`);
}
export class ITicketsPackage extends GlobalSchemaFields {
  sid;
  /** @type {IClientProfile} */
  created_by;
  /** @type {IServiceProduct} */
  product;
  /** @type {number} */
  quota;
  /** @type {number} */
  quotaLeft;
  /** @type {IClientProfile} */
  sharedWith;
  /** @type {Date} */
  expireAt;
  /** @type {number} */
  amountPayable;

  constructor(obj) {
    super(obj);
    obj.created_by = populatedCtor(obj.created_by, IProfile);
    obj.product = populatedCtor(obj.product, IServiceProduct);
    obj.sharedWith = populatedCtor(obj.sharedWith, IProfile);
    obj.expireAt = parseDate(obj.expireAt);
    Object.assign(this, obj);
  }
}
