<template>
  <div>
    <ListView
      title="Specific Products Pricing"
      @reload="reload"
      @create="actionCreate"
      @navDetails="actionDetails"
      :search.sync="search"
      :data="data"
      :header="header"
    >
      <template v-slot:item.name="{ item }">
        {{ parseLocalizedString(item.product.name).en }}
      </template>
      <!-- <template v-slot:item.name_hk="{ item }">
        {{ parseLocalizedString(item.name)['zh-hk'] }}
      </template>  -->
    </ListView>
    <ProductListDialog
      v-if="displayProductList"
      v-model="displayProductList"
      :id="id"
      @saveProducts="save"
      store
    />
    <ProductStoreDetailDialog
      v-if="displayProductDetailDialog && productStoreId"
      v-model="displayProductDetailDialog"
      :productStoreId="productStoreId"
      @updateProductStore="saveProductStore"
      @resetProductPrice="resetProductPrice"
    />
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import ProductListDialog from '@/components/PhysicalProduct/ProductListDialog';
import ProductStoreDetailDialog from '@/components/ProductStore/ProductStoreDetailDialog';
import QrcodeVue from 'qrcode.vue';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import {
  assignStoreProducts,
  updateStoreProduct,
  resetStoreProductPrice,
} from '@/api/productStore';
import { RESTFUL } from '@/data/constants';
import ListView from '@/components/ListView';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid } from '@/services/utils';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'StoreDetails',
  components: { CustomLabel, ListView, ProductListDialog, ProductStoreDetailDialog },
  mixins: [listViewPageMixin],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      header: [
        // { text: 'Product Name', value: 'product.name' },
        { text: 'Product Name', value: 'name' },
        // { text: 'Product Name(EN)', value: 'product.name' },
        // { text: 'Product Name(HK)', value: 'product.name' },
        { text: 'Listed Price', value: 'listedPrice' },
        { text: 'Discounted Price', value: 'discountedPrice' },
      ],
      data: [],
      search: '',
      displayProductList: false,
      displayProductDetailDialog: false,
      productStoreId: '',
    };
  },
  mounted() {
    this.loadStore();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    restfulURL() {
      return `toca/product-stores/${this.id}/products`;
    },
  },
  methods: {
    parseLocalizedString,
    async loadStore() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.stores.read, this.id);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    // async reload() {
    //   if (!this.isNew) {
    //     this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
    //   }
    // },
    async actionCreate() {
      // TODO: POP UP WORKSPACES LIST HERE
      this.displayProductList = true;
    },
    async saveWarehouseToStore() {
      // TODO: create rest action here
    },
    async actionCancel() {
      this.displayProductList = false;
    },
    async actionDetails(productStore) {
      this.displayProductDetailDialog = true;
      this.productStoreId = productStore._id.toString();
    },
    async save(productsData) {
      const productIds = productsData.products.map(p => getOid(p));
      const removeProductIds = productsData.removeProductIds;
      try {
        // if (!(await this.$refs.form?.validate?.())) {
        //   return;
        // }
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await assignStoreProducts(api, this.id, {
          productIds,
          removeProductIds,
        });

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });

        // if (this.isNew) {
        //   this.$router.replace({
        //     name: 'vipProduct',
        //     params: {
        //       id: model._id,
        //     },
        //   });
        // }
        this.displayProductList = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveProductStore(productStoreData) {
      try {
        if (this.isNew) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Create Store First',
            type: 'error',
            color: 'error',
          });
          return;
        }

        const model = await updateStoreProduct(api, this.productStoreId, productStoreData);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });

        this.displayProductDetailDialog = false;
        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async resetProductPrice(productStoreData) {
      const model = await resetStoreProductPrice(api, this.productStoreId, productStoreData);

      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Saved',
        type: 'success',
        color: 'success',
      });

      this.displayProductDetailDialog = false;
      this.reload();
    },
  },
};
</script>
