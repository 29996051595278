import { GlobalSchemaFields } from '@/api/mongooseTypes';

/**
 * @param {AxiosInstance} api
 * @param {string} id
 */
export async function serviceProductList(api, id) {
  await api.post(`toca/admin/serviceProducts/${id}`);
}

/**
 * @param {AxiosInstance} api
 * @param {{select: string}} [params]
 */
export async function listServiceProduct(api, params) {
  return (await api.get('toca/admin/serviceProducts', { params })).data;
}

export class IServiceProduct extends GlobalSchemaFields {
  sid;
  /** @type {string} */
  name;
  /** @type {number} */
  listedPrice;
  /** @type {string} */
  description;
  /** @type {number} */
  discountedPrice;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}
