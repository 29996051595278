var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{attrs:{"elevation":"0","min-height":"250"}},[_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Stock"}}),_c('ValidationProvider',{attrs:{"name":"_stock","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"type":"number","outlined":""},model:{value:(_vm.model._stock),callback:function ($$v) {_vm.$set(_vm.model, "_stock", $$v)},expression:"model._stock"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Reason"}}),_c('ValidationProvider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","items":_vm.productActivityLogUpdateReasonOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.updateReason),callback:function ($$v) {_vm.$set(_vm.model, "updateReason", $$v)},expression:"model.updateReason"}})]}}],null,true)}),(_vm.model.updateReason === _vm.ProductActivityLogUpdateReason.Other)?[_c('ValidationProvider',{attrs:{"name":"Other Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other Reason","outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.remarks),callback:function ($$v) {_vm.$set(_vm.model, "remarks", $$v)},expression:"model.remarks"}})]}}],null,true)})]:_vm._e()],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }