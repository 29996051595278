import { mapValues } from 'lodash';
import moment from 'moment';

/**
 * @param ref
 * @param {Function} ctor
 */
export function populatedCtor(ref, ctor) {
  return ref instanceof ctor ? ref : ref && typeof ref === 'object' ? new ctor(ref) : ref;
}

/**
 * @param {*[]} refs
 * @param {Function} ctor
 */
export function populatedArrayCtor(refs, ctor) {
  return Array.isArray(refs) ? refs.map(x => populatedCtor(x, ctor)) : refs;
}

/**
 * @param {*[]} refs
 * @param {Function} ctor
 */
export function populatedReadCtor(refs, ctor) {
  return Array.isArray(refs)
    ? populatedArrayCtor(refs, ctor)
    : mapValues(refs, ref => populatedCtor(ref, ctor));
}

/**
 * @param {PaginationResult} paginationResult
 * @param {Function} ctor
 * @return {PaginationResult}
 */
export function populatedListCtor(paginationResult, ctor) {
  paginationResult.docs = populatedArrayCtor(paginationResult.docs, ctor);
  return paginationResult;
}

export function parseDate(v) {
  return v ? moment(v).toDate() : v;
}

export class GlobalSchemaFields {
  /** @type {string} */
  _id;
  /** @type {Date} */
  created_at;
  /** @type {Date} */
  updated_at;

  constructor(doc) {
    if (doc) {
      doc.created_at = parseDate(doc.created_at);
      doc.updated_at = parseDate(doc.updated_at);
      Object.assign(this, doc);
    }
  }
}

export class IDateRange {
  /** @type {Date} */
  start;
  /** @type {Date} */
  end;

  constructor(doc) {
    if (doc) {
      doc.start = parseDate(doc.start);
      doc.end = parseDate(doc.end);
      Object.assign(this, doc);
    }
  }

  /**
   * @param {Date} t
   * @return {boolean}
   */
  contains(t) {
    return this.start <= t && this.end >= t;
  }
}
