import { GlobalSchemaFields, populatedListCtor } from '@/api/mongooseTypes';
import queryString from 'query-string';

export class IWarehouseStock extends GlobalSchemaFields {
  /** @type {string} **/
  warehouse;
  /** @type {string} **/
  product;
  /** @type {number} **/
  qty;
  /** @type {number} **/
  _stock;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }

  // constructor(obj) {
  //   super(obj);
  //   obj.listedPrice = populatedCtor(obj.listedPrice, number);
  //   obj.discountedPrice = populatedCtor(obj.discountedPrice, number);
  //   obj.store = populatedCtor(obj.store, IStore);
  //   obj.product = populatedCtor(obj.booking, IProduct);
  //   Object.assign(this, obj);
  // }
}

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function assignWarehouseStock(api, storeId, data) {
  return (await api.patch(`toca/warehouse-stocks/${storeId}/assign-warehouse-stock`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} warehouseStockId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IStore>>}
 */
export async function updateWarehouseStock(api, warehouseStockId, data) {
  return (await api.patch(`toca/warehouse-stocks/${warehouseStockId}`, data)).data;
}

// /**
//  * @param {AxiosInstance} api
//  * @param {string} storeId
//  * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
//  * @return {Promise<PaginationResult<IWarehouse>>}
//  */
// export async function getProductsByStoreId(api, storeId, options) {
//   return (await api.get(`toca/product-stocks/${storeId}/warehouses`)).data;
// }

/**
 * @param {AxiosInstance} api
 * @param {string} storeId
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: *, populate?: *}} [options]
 * @return {Promise<PaginationResult<IWarehouse>>}
 */
export async function getMatchedProducts(api, warehouseId, data, options) {
  return (
    await api.get(
      `toca/warehouse-stocks/${warehouseId}/match-products?${queryString.stringify(data)}`,
    )
  ).data;
}
